<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="5">
                                <label class="f-label">{{$t("На_бланке")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="7">
                                <v-switch
                                    v-model="onBlank"
                                    inset
                                    hide-details                                                    
                                    class="cust-switch"
                                />
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="5">
                                <label class="f-label">{{ $t("Скрыть_список_исполнителей") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="7">
                                <v-switch
                                    v-model="hideExecuters"
                                    inset
                                    hide-details                                                    
                                    class="cust-switch"
                                />
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="5">
                                <label class="f-label">{{ $t("Язык_отчета") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="7">
                                <v-select
                                    v-model="language"
                                    :items="languages"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-show="language != null"
                >
                    {{ $t("Сформировать") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "PrintFishkaDlg",
    data () {
        return {
            title: "Параметры_фишки",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            languages: [
                { id: 'kk-KZ', Value: this.$t("казахский") },
                { id: 'ru-RU', Value: this.$t("русский") }
            ]
        }
    },
    computed: {
        ...mapGetters('dialogs/printFishka', { visible: 'isVisible' }),
        onBlank: {
            get: function() {
                return this.$store.getters['dialogs/printFishka/isOnBlank'];
            },
            set: function(v) {
                this.$store.commit('dialogs/printFishka/SET_ON_BLANK', v);
            }
        },
        hideExecuters: {
            get: function() {
                return this.$store.getters['dialogs/printFishka/isHideExecuters'];
            },
            set: function(v) {
                this.$store.commit('dialogs/printFishka/SET_HIDE_EXECUTERS', v);
            }
        },
        language: {
            get: function() {
                return this.$store.getters['dialogs/printFishka/getLanguage'];
            },
            set: function(v) {
                this.$store.commit('dialogs/printFishka/SET_LANGUAGE', v);
            }
        },
    },
    methods: {
        ...mapActions('dialogs/printFishka', ['ok', 'cancel'])
    }
}
</script>